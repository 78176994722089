import React from 'react'
import Layout from '../../components/Global/Layout'
import { graphql } from 'gatsby'
import FAQ from '../../components/FAQ'
import { SCHEMATYPES } from '../../constants'

const locale = 'en-CA'

export const query = graphql`
  {
    allContentfulFaqPage {
      nodes {
        title
        bodyText1
        seoContent {
          internal {
            content
          }
        }
        node_locale
      }
    }
    allContentfulFaqType {
      edges {
        node {
          name
          node_locale
          id
        }
      }
    }
    allContentfulFaq {
      edges {
        node {
          answer {
            answer
          }
          question
          type {
            id
          }
          link
          richAnswer {
            childContentfulRichText {
              html
            }
          }
        }
      }
    }
  }
`

const FaqPage = (props) => {
  const pageData = props.data?.allContentfulFaqPage.nodes.filter(
    (node) => node.node_locale === locale
  )[0]

  const faqData = props.data?.allContentfulFaqType.edges
    ?.filter((node) => node.node.node_locale === locale)
    ?.map((type) => {
      type.node.faq =
        props.data.allContentfulFaq?.edges?.filter(
          (faq) => faq.node.type?.id === type.node.id
        ) || []
      return type
    })

  return (
    <Layout
      locale={locale}
      url='/faqs'
      title={JSON.parse(pageData?.seoContent.internal.content).title}
      desc={JSON.parse(pageData?.seoContent.internal.content).desc}
      strucData={{
        type: SCHEMATYPES.FAQ,
        FAQs: faqData
      }}
    >
      <FAQ faqData={faqData} pageContent={pageData} locale={locale} />
    </Layout>
  )
}

export default FaqPage
